import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import { AddressElement, Elements, LinkAuthenticationElement, useElements } from "@stripe/react-stripe-js";
import { ShopContext, IShopContext } from "../../context/shop-context";
import { useGetProducts } from "../../hooks/useGetProducts";
import { CartItem } from "../cart/cart-item";
import { IProduct } from "../../models/interfaces";
import "./PaymentForm.css";
import { useNavigate, useParams } from "react-router-dom";
import { EmailForm } from "../email/EmailForm";


interface Props {
    product: IProduct;
}

export const Payment = (props: Props) => {

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const { getTotalCartAmount, getCartItems } = useContext<IShopContext>(ShopContext);
    const { products } = useGetProducts();
    const totalAmount = getTotalCartAmount();
    const cartItems = getCartItems();
    const [ newEmail, setNewEmail ] = useState("");
    const [ nextStep, setNextStep ] = useState(false);
    const { orderID } = useParams();
    const finalAmount = totalAmount + 12;
    const navigate = useNavigate()
    const PI_GATEWAY = process.env.PAYMENT_INTENT_GATEWAY;
    const devConfigURL = process.env["CONFIG_URL"];
    const productionConfigUrl = process.env["PRODUCTION_CONFIG_URL"];


    const appearance = {
        theme: 'flat' as 'flat',
        variables: {
            colorPrimary: 'black',
            colorBackground: '#fafafa',
            colorText: '#000000',
            colorDanger: '#9c122d',
            spacingUnit: '4px',
            borderRadius: '1px',
        },
    };


    const fetchStripeConfig = useCallback(async () => {
        try {
            const res = await fetch(productionConfigUrl);
            const data = await res.json();
            setStripePromise(loadStripe(data.publishableKey));
        } catch (e) {
            console.log("Error fetching stripePromise: ", e);
        }
    }, []);


    
    const fetchClientSecret = useCallback(async () => {
        if (cartItems.length === 0) {
            navigate("/")
        } else { 
            try {
                const res = await fetch(PI_GATEWAY, {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ finalAmount, cartItems, orderID }),
                });
                const data = await res.json();
                
                setClientSecret(data.clientSecret);
            } catch (e) {
                console.log("Error fetching clientSecret: ", e);
            }
        }
    }, []);


    useEffect(() => {
        fetchStripeConfig();
        fetchClientSecret();
    }, [fetchStripeConfig, fetchClientSecret]);

    return (
        <div className="checkout-container">

<div className="checkout-info-container">
    
                <p id="order-summary-text"> ORDER SUMMARY: </p>
                <div className="checkout-items">
                    {cartItems.map(({ productId, quantity, size }) => {
                        const product = products.find(p => p._id === productId);
                        return product ? (
                            <CartItem 
                                product={product}
                                quantity={quantity}
                                size={size}
                                key={`${productId}-${size}`}
                                isCheckout={true}
                            />
                        ) : (
                            <div>NO PRODUCT FOUND!</div>
                        )})}
                </div>
                <div className="subtotal"> SUBTOTAL: €{totalAmount} 
                    <p className="shipping">SHIPPING: €12</p>
                    TOTAL: €{finalAmount}
                </div>
            </div>
            {stripePromise && clientSecret &&  (
                <div className="elements">
    
                    <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
                    <div className="email-form">
                        <p> CONTACT INFO </p>
                        <EmailForm/>
                    </div>
                        <div>
                            <AddressElement
                                options={{ mode: "shipping",
                                    fields: {phone:
                                        "always",
                                    }
                                
                                 }}
                        
                            />
                            <p> BILLING DETAILS </p>
                     <PaymentForm />
                        </div>
                    </Elements>

                </div>
            )}

        
        </div>
    );
}

export default Payment;
